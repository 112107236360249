import React, { ReactElement } from 'react';

import { useRamblerUser } from '@rambler-help/helpers';

import { styled } from '@linaria/react';
import { fontDefault } from '@rambler-help/shared';

const Profile = styled.div<{ withAvatar?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  padding: ${({ withAvatar }) => (withAvatar ? '15px 20px 15px 85px' : '15px 20px')};
  ${fontDefault}
  background-color: #eff5ff;
  border-radius: 15px;
  & > * {
    margin-bottom: 10px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;
const Avatar = styled.div<{ url?: string }>`
  position: absolute;
  top: 15px;
  left: 20px;
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 25px;
  background-color:#eee;
  background-size: cover;
  background-image: ${({ url }) => (url ? `url(${url})` : 'none')};
`;
const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #343b4c;
`;
// const Attention = styled.div`
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 20px;
//   color: #ff564e;
//   padding: 0 0 0 25px;
//   position: relative;
//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 20px;
//     height: 20px;
//     background-image: url('./images/icon-attention.svg');
//   }
// `;
const LinkToProfile = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #343b4c;
  & a {
    color: #315efb;
    display: inline-block;
    padding: 0 25px 0 0;
    position: relative;
    text-decoration: none;
  }
  & a:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url('./images/icon-forward.svg');
  }
  & a:hover {
    color: #2c54e2;
  }
  & a:hover:after {
    background-image: url('./images/icon-forward-hover.svg');
  }
  & a:active {
    color: #274bc8;
  }
  & a:active:after {
    background-image: url('./images/icon-forward-active.svg');
  }
`;

const ProfileComponent = (): ReactElement | null => {
  const ramblerUser = useRamblerUser();
  const withAvatar = !!(ramblerUser && ramblerUser.display?.avatar?.url);
  const profileName = (
    ramblerUser &&
    (
      ramblerUser.display?.display_name ||
      [
        ramblerUser.profile?.firstname,
        ramblerUser.profile?.lastname
      ].filter(item => item && item.length).join(' ') || ''
    )
  );

  return (
    ramblerUser ? (
      <Profile withAvatar={withAvatar}>
        {withAvatar && (<Avatar url={ramblerUser.display?.avatar?.url} />)}
        <Name>
          {ramblerUser ? profileName : ''}
        </Name>
        {/* <Attention>Защита профиля: 20%</Attention> */}
        <LinkToProfile>
          <a href="https://id.rambler.ru/" target="_blank" rel="noreferrer">Перейти в профиль</a>
        </LinkToProfile>
      </Profile>
    ) : null
  );
};

export default ProfileComponent;
