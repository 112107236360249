import React, { ReactNode, useContext } from 'react';

import LayoutStore from './LayoutStore';
import ModalsStore from './ModalsStore';
import ToplineUserStore from './ToplineUserStore';
import SearchStore from './SearchStore';
import ProjectsStore from './ProjectsStore';
import SectionsStore from './SectionsStore';
import ArticlesStore from './ArticlesStore';
import FeedbackFormsStore from './FeedbackFormsStore';
import FeedbackRequestsStore from './FeedbackRequestsStore';
import { EStore } from '@rambler-help/shared';
import { rehydrate } from '@rambler-help/stores';
import StoreContext from './context';

export interface IStore {
  layout: LayoutStore;
  modals: ModalsStore;
  toplineUser: ToplineUserStore;
  search: SearchStore;
  projects: ProjectsStore;
  sections: SectionsStore;
  articles: ArticlesStore;
  feedbackForms: FeedbackFormsStore;
  feedbackRequests: FeedbackRequestsStore;
}

export class RootStore implements IStore {

  layout = new LayoutStore(rehydrate((state) => state[EStore.Layout]));
  modals = new ModalsStore(rehydrate((state) => state[EStore.Modals]));
  toplineUser = new ToplineUserStore();
  search = new SearchStore(rehydrate((state) => state[EStore.Search]));
  projects = new ProjectsStore(rehydrate((state) => state[EStore.Projects]));
  sections = new SectionsStore(rehydrate((state) => state[EStore.Sections]));
  articles = new ArticlesStore(rehydrate((state) => state[EStore.Articles]));
  feedbackForms = new FeedbackFormsStore(rehydrate((state) => state[EStore.FeedbackForms]));
  feedbackRequests = new FeedbackRequestsStore(rehydrate((state) => state[EStore.FeedbackRequests]));

}

let rootStore: RootStore;

export const initializeRootStore = (): RootStore => {
  const store = rootStore ?? new RootStore();
  if (__SERVER__) {
    return (store);
  }
  if (!rootStore) {
    rootStore = store;
  }
  return (store);
};

export const RootStoreProvider = ({
  store,
  children
}: {
  store: RootStore;
  children: ReactNode;
}) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useRootStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }
  return (context);
};
