import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';

import { RouteNames } from '@rambler-help/shared';
import { PORT } from'@constants/server';
import { logError } from '@rambler-help/stores';

const RESULTS_PER_PAGE = 10;

export interface ISearchResult {
  id: number;
  sectionId: number;
  sectionTitle?: string;
  projectId: number;
  projectTitle?: string;
  title: string;
  url: string;
  body: string;
}

export interface ISearchRead {
  matches: Array<ISearchResult>;
  total: number;
}

export default class SearchStore {

  constructor(initialData?: SearchStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  query: string | undefined;
  results: string | undefined;
  resultsTotal: number | undefined;
  isComplete: boolean | undefined;
  pagesCount = 0;
  currentPage = 0;

  search = async (query: string, page?: number): Promise<Array<ISearchResult> | null> => {
    try {
      const searchResults = await axios.get(
        (__SERVER__ ? 'http://localhost:' + PORT : '' ) +
        (
          RouteNames.API_SEARCH
            .replace(':query', encodeURIComponent(query))
        ) + '/' +
        '?from=' + (page ? page : 0) +
        '&size=' + RESULTS_PER_PAGE
      );
      if (searchResults) {
        runInAction(() => {
          this.query = query;
          this.results = encodeURIComponent(JSON.stringify(searchResults.data.matches));
          this.resultsTotal = searchResults.data.total;
          const pagesCount = Math.floor(searchResults.data.total / RESULTS_PER_PAGE);
          this.pagesCount = pagesCount > 1 ? pagesCount : 0;
          if (page) {
            this.currentPage = page;
          }
        });
        return searchResults.data.matches || null;
      } else {
        runInAction(() => {
          this.query = query;
        });
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  searchByProject = async (
    projectslug: string,
    query: string,
    page?: number
  ): Promise<Array<ISearchResult> | null> => {
    try {
      const searchResults = await axios.get(
        (__SERVER__ ? 'http://localhost:' + PORT : '' ) +
        (
        RouteNames.API_SEARCH_PROJECT
          .replace(':projectSlug', projectslug)
          .replace(':query', encodeURIComponent(query))
        ) + '/' +
        '?from=' + (page ? page : 0) +
        '&size=' + RESULTS_PER_PAGE
      );
      if (searchResults) {
        runInAction(() => {
          this.query = query;
          this.results = encodeURIComponent(JSON.stringify(searchResults.data.matches));
          this.resultsTotal = searchResults.data.total;
          const pagesCount = Math.floor(searchResults.data.total / RESULTS_PER_PAGE);
          this.pagesCount = pagesCount > 1 ? pagesCount : 0;
          if (page) {
            this.currentPage = page;
          }
        });
        return searchResults.data.matches || null;
      } else {
        runInAction(() => {
          this.query = query;
        });
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

}
