import React, { ReactElement } from 'react';
import Footer, { SocialLinkType } from '@rambler-components/footer';

import { styled } from '@linaria/react';
import '@rambler-components/footer/styles.css';

const FooterUser = styled.div`
  * {
    box-sizing: border-box;
  }
  ul li::before {
    display: none;
  }
`;

const menuLinksDesktop = [
  // {
  //   title: 'Правила пользования и конфиденциальность',
  //   href: '/legal',
  //   target: '_blank',
  // },
  {
    title: 'Условия использования',
    href: '/legal/1430',
    target: '_blank',
  },
  {
    title: 'Политика конфиденциальности',
    href: '/legal/1142',
    target: '_blank',
  },
];

const socialLinks = [
  {
    code: 'vk',
    href: 'https://vk.com/rambler',
  },
  {
    code: 'ok',
    href: 'https://ok.ru/rambler',
  },
  {
    code: 'tg',
    href: 'https://t.me/news_rambler',
  },
  {
    code: 'rss',
    href: 'https://news.rambler.ru/rss/',
  },
];

const FooterUserComponent = (): ReactElement => {
  return (
    <FooterUser id="footer">
      <Footer
        menuLinks={menuLinksDesktop}
        socialLinks={socialLinks as SocialLinkType[]}
        ageLimit={18}
      />
    </FooterUser>
  );
};

export default FooterUserComponent;
