import React from 'react';

import { IRoute, RouteNames } from '@rambler-help/shared';

import MainPageContent from '@pages/Main';
import FeedbackFormUser from '@pages/FeedbackForm';
import Project from '@pages/Project';
import Article from '@pages/Article';
import Search from '@pages/Search';
import Legal from '@pages/seo/Legal';

export const routes: IRoute[] = [

  {
    path: RouteNames.USER_HOME_PAGE,
    element: <MainPageContent />,
  },
  {
    path: RouteNames.USER_COMMON_ARTICLE_PAGE,
    element: <Article />,
  },
  {
    path: RouteNames.USER_LEGAL_PAGE_SEO,
    element: <Legal />,
  },
  {
    path: RouteNames.USER_LEGAL_ARTICLE_PAGE,
    element: <Article />,
  },
  {
    path: RouteNames.USER_FEEDBACK_COMMON_FORM_PAGE,
    element: <FeedbackFormUser />,
  },
  {
    path: RouteNames.USER_FEEDBACK_FORM_PAGE,
    element: <FeedbackFormUser />,
  },
  {
    path: RouteNames.USER_FEEDBACK_FORM_BY_ID_PAGE,
    element: <FeedbackFormUser />,
  },
  {
    path: RouteNames.USER_PROJECT_PAGE,
    element: <Project />,
  },
  {
    path: RouteNames.USER_SECTION_PAGE,
    element: <Article />,
  },
  {
    path: RouteNames.USER_ARTICLE_PAGE,
    element: <Article />,
  },
  {
    path: RouteNames.USER_SEARCH_FULL_EMPTY_PAGE,
    element: <Search />,
  },
  {
    path: RouteNames.USER_SEARCH_FULL_PAGE,
    element: <Search />,
  },
  {
    path: RouteNames.USER_SEARCH_EMPTY_PAGE,
    element: <Search />,
  },
  {
    path: RouteNames.USER_SEARCH_PAGE,
    element: <Search />,
  },

];
