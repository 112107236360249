require('./app');

if (module.hot) {
  module.hot.accept('./app', () => {
    try {
      require('./app');
    } catch (error) {
      console.error('🔴', error);
    }
  });
  console.info('🟢 Client-side HMR enabled');
}
