import { createContext } from 'react';

import { RootStore } from './index';

// Контекст StoreContext должен располагаться в отдельном файле.
// При размещении рядом с местом использования, в случае обновления компонента
// подключенного с помощью "@loadable/component" через react-hot-reloader,
// контекст устанавливается в значение по умолчпнию.
const StoreContext = createContext<RootStore | undefined>(undefined);

export default StoreContext;
