import { runInAction, makeAutoObservable } from 'mobx';

import {
  TFeedbackFormRead,
  findFeedbackFormById,
  findFeedbackFormsByProjectId
} from '@rambler-help/prisma';
import { logError } from '@rambler-help/stores';

export default class FeedbackFormsStore {

  constructor(initialData?: FeedbackFormsStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  form: TFeedbackFormRead | undefined;
  forms: Array<TFeedbackFormRead> | undefined;
  request: {
    id: string;
    isComplete: boolean;
  } | undefined;

  load = async (
    params: { projectId?: number; formId?: number; }
  ): Promise<Array<TFeedbackFormRead> | null> => {
    try {
      if (params.formId) {
        const form = await findFeedbackFormById({
          id: params.formId,
        });
        if (form) {
          runInAction(() => {
            this.form = form;
          });
        } else {
          return null;
        }
      }
      if (params.projectId) {
        const forms = await findFeedbackFormsByProjectId({
          id: params.projectId,
        });
        if (forms) {
          runInAction(() => {
            this.forms = forms;
          });
        } else {
          return null;
        }
      }
      return null;
    } catch(error) {
      logError(error);
    }
    return null;
  };

}
