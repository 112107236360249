import { runInAction, makeAutoObservable } from 'mobx';

import {
  TSectionRead,
  findSectionsByProjectId
} from '@rambler-help/prisma';
import {
  TArticleRead,
  findArticlesByIds
} from '@rambler-help/prisma';
import { logError } from '@rambler-help/stores';

export default class SectionsStore {

  constructor(initialData?: SectionsStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  sections: Array<TSectionRead> | undefined;
  activeSection: TSectionRead | undefined;
  activeSubsection: TArticleRead | undefined;

  load = async (projectId: number): Promise<Array<TSectionRead> | null> => {
    try {
      const sectionsByProject = await findSectionsByProjectId({
        id: projectId,
        orderBy: {
          position: 'asc',
        },
      });
      const sectionIds = sectionsByProject?.map(section => section.id) || [];
      const articlesBySections = await findArticlesByIds({
        ids: sectionIds,
      });
      const allSections: Array<TSectionRead> = sectionsByProject?.map(
        section => this.constructSection(section, articlesBySections)
      ) || [];
      runInAction(() => {
        if (allSections.length) {
          this.sections = allSections;
        }
      });
      return allSections;
    } catch(error) {
      logError(error);
    }
    return null;
  };

  constructSection = (section: TSectionRead, articlesBySections?: Array<TArticleRead> | null) => {
    return {
      ...section,
      subsections: (
        articlesBySections
        ? articlesBySections
          .filter(
            article => article.sectionId === section.id
          )
          .sort((a, b) => (a.position - b.position))
          .map((article) => ({
            ...article,
            body: (
              article.body
                .replace(/<head\b[^<]*(?:(?!<\/head>)<[^<]*)*<\/head>/gi, '')
                .replace(/<meta\b[^<]*(?:(?!<\/meta>)<[^<]*)*<\/meta>/gi, '')
                .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
            ),
            slug: article.id.toString(),
          }))
        : undefined
      ),
    };
  };

  setActiveSection = (sectionSlug?: string): void => {
    if (typeof sectionSlug === 'undefined') {
        this.activeSection = undefined;
    } else {
      const sections = this.sections?.filter(section => {
        return (section.slug === sectionSlug);
      });
      if (sections && sections[0]) {
        this.activeSection = sections[0];
      }
    }
  };

  setActiveSubsection = (subsectionId?: number): void => {
    if (typeof subsectionId === 'undefined') {
      runInAction(() => {
        this.activeSection = undefined;
        this.activeSubsection = undefined;
      });
    } else {
      const sections = this.sections?.filter(section => {
        const subsections = section.subsections?.filter(
          subsection => subsection.id === subsectionId
        );
        return (subsections && subsections.length);
      });
      runInAction(() => {
        if (sections && sections[0]) {
          this.activeSection = sections[0];
          const subsections = sections[0].subsections?.filter(
            subsection => subsection.id === subsectionId
          );
          if (subsections && subsections[0]) {
            this.activeSubsection = subsections[0];
          }
        }
      });
    }
  };

}
