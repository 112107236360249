import { makeAutoObservable } from 'mobx';

export default class ToplineUserStore {

  constructor(initialData?: ToplineUserStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  username: string | undefined;
  email: string | undefined;
  
  setUsername = (username: string): void => {
    this.username = username;
  };

  setEmail = (email: string): void => {
    this.email = email;
  };

}
