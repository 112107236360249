import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Page from '@containers/Page';
import { RootStoreProvider, initializeRootStore } from '@stores/index';

export function createPage() {
  return (
    <BrowserRouter>
      <RootStoreProvider store={initializeRootStore()}>
        <Page />
      </RootStoreProvider>
    </BrowserRouter>
  );
}
