import React, { ReactElement } from 'react';

import FeedbackRequestSent from './FeedbackRequestSent';

import '@rambler-components/popup/styles.css';

const Modals = (): ReactElement => {
  return (
    <React.Fragment>
      <FeedbackRequestSent />
    </React.Fragment>
  );
};

export default Modals;
