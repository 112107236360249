import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import Popup from '@rambler-components/popup';

import { EModal } from '@rambler-help/shared';
import { useRootStore } from '@stores/index';

import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { fontDefault } from '@rambler-help/shared';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 135px;
  margin: -20px;
  padding: 20px;
  ${fontDefault}
  background-color: #eff5ff;
  border-radius: 15px;
`;
const Icon = styled.div`
  box-sizing: border-box;
  width: 60px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
`;
const IconLike = css`
  background-image: url('./images/icon-like.svg');
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
  ${fontDefault}
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
  & > div {
    margin-left: 25px;
  }
`;
const Description = styled.div``;

const FeedbackRequestSentModal = observer((): ReactElement => {
  const { modals } = useRootStore();
  return (
    <Popup
      isOpen={modals.feedbackRequestSent}
      title=""
      width={400}
      withCloseButton={false}
      closeOnClickOutside={true}
      closeOnEsc={true}
      // onConfirmation={() => {return;}}
      // onCancel={() => {return;}}
      onClose={() => {modals.hide(EModal.FeedbackRequestSent)}}
    >
      <Content>
        <Icon className={IconLike} />
        <Title>Спасибо за обращение</Title>
        <Description>Мы пришлем ответ на указанную вами почту</Description>
      </Content>
    </Popup>
  );
});

export default FeedbackRequestSentModal;
