import { makeAutoObservable } from 'mobx';
import axios from 'axios';

import { RouteNames } from '@rambler-help/shared';
import { EEditingError } from '@rambler-help/shared';
import { logError } from '@rambler-help/stores';

export default class FeedbackRequestsStore {

  constructor(initialData?: FeedbackRequestsStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  feedbacksPerHourOverLimit?: boolean;
  feedbacksPerDayOverLimit?: boolean;
  request!: {
    id: string;
    isComplete: boolean;
    error?: EEditingError;
  };

  setFeedbacksPerHourOverLimit = (flag: boolean): void => {
    this.feedbacksPerHourOverLimit = flag;
  };

  setFeedbacksPerDayOverLimit = (flag: boolean): void => {
    this.feedbacksPerDayOverLimit = flag;
  };

  clearError = (): void => {
    this.request && delete this.request.error;
  };

  createRequest = async (requestData: FormData): Promise<void> => {
    try {
      const result: {
        data: IEditingResponse & {
          feedbacksPerHourOverLimit?: boolean;
          feedbacksPerDayOverLimit?: boolean;
        };
      } = await axios.post(
        RouteNames.API_FEEDBACK_REQUEST_CREATE,
        requestData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const request: IEditingResponse['request'] = {
        id: result.data.request.id,
        isComplete: false,
      };
      if (result.data.request.isComplete) {
        request.isComplete = true;
      }
      if (result.data.feedbacksPerHourOverLimit) {
        this.feedbacksPerHourOverLimit = true;
      }
      if (result.data.feedbacksPerDayOverLimit) {
        this.feedbacksPerDayOverLimit = true;
      }
      this.request = request;
    } catch(error) {
      logError(error);
    }
  };

}
