import { makeAutoObservable } from 'mobx';

import { EModal } from '@rambler-help/shared';

interface IModalsParams {
  requestId?: string;
}

export default class ModalsStore {

  constructor(initialData?: ModalsStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  feedbackRequestSent = false;
  
  params: IModalsParams = {};

  show = (name: EModal, params?: IModalsParams): void => {
    this[name] = true;
    if (params) {
      this.params = params;
    }
  };

  hide = (name: EModal): void => {
    this[name] = false;
    this.params = {};
  };

}
