import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DefaultLayout from '@layouts/Default';
import { routes } from '@routes/index';

const Page = observer((): ReactElement => {
  return (
    <DefaultLayout>
      <Routes>
        {routes.map((route, i) =>
          <Route
            key={i}
            path={route.path}
            element={route.element}
          />
        )}
      </Routes>
    </DefaultLayout>
  );
});

export default Page;
