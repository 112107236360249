import { hydrate } from 'react-dom';
import { configure as mobXConfigure } from 'mobx';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';

import { createPage } from './render';

// Конфигурируем MobX-хранилище.
mobXConfigure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  observableRequiresReaction: false,
  reactionRequiresObservable: true,
});

// Конфигурируем Sentry.
Sentry.init({
  dsn: 'https://fd2c94fed0d0c8a48087936456b3434a@sentry-saas.rambler-co.ru/135',
  // @TODO: Убрать после проверки нового Sentry в production'е.
  // dsn: 'https://2ab1df81fefa47ed8e40313878642139@sentry-search-00.rambler-co.ru/52',
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  release: APP_VERSION,
});

// "Гидрируем" для браузера созданное на сервере приложение.
loadableReady(() => {
  const root = document.getElementById('root');
  const app = createPage();
  if (__DEV__) {
    document.body.style.display = 'block';
    // document.body.classList.remove('hidden');
  }
  hydrate(app, root);
});
