import { runInAction, makeAutoObservable } from 'mobx';
import { Section, Article } from '@prisma/client';

import {
  TSectionRead,
  findSections,
  findSectionsByProjectId
} from '@rambler-help/prisma';
import {
  TArticleRead,
  findArticleById,
  findArticlesByIds,
  findCommonArticlesByIds,
  findLegalArticlesByIds,
  findPopularArticlesByIds,
} from '@rambler-help/prisma';
import {
  findArticlesPopular,
  findArticlePopularByArticleId,
  createArticlePopular,
  updateArticlePopular
} from '@rambler-help/prisma';
import {
  findArticlesCommonByProjectId
} from '@rambler-help/prisma';
import { logError } from '@rambler-help/stores';

export default class ArticlesStore {

  constructor(initialData?: ArticlesStore) {
    if (initialData) {
      Object.assign(this, initialData);
    }
    makeAutoObservable(this);
  }

  article: TArticleRead | undefined;
  popular: Array<TArticleRead> | undefined;
  activeCommonArticle: TArticleRead | undefined;
  common: Array<TArticleRead> | undefined;
  activeLegalArticle: TArticleRead | undefined;
  legal: Array<TArticleRead> | undefined;
  popularInProject: Array<TArticleRead> | undefined;

  loadById = async (articleId: number): Promise<TArticleRead | null> => {
    try {
      const article = await findArticleById({
        id: articleId,
      });
      if (article) {
        this.increasePopularity(article);
        article.body = (
          (
            article.body
              .replace(/<head\b[^<]*(?:(?!<\/head>)<[^<]*)*<\/head>/gi, '')
              .replace(/<meta\b[^<]*(?:(?!<\/meta>)<[^<]*)*<\/meta>/gi, '')
              .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
          ) || ''
        );
        const meta = article.meta ? JSON.parse(article.meta) : {};
        if (meta.prevId) {
          article.prevId = meta.prevId;
        }
        if (meta.nextId) {
          article.nextId = meta.nextId;
        }
        runInAction(() => {
          this.article = article;
        });
        return article;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadPopular = async (): Promise<Array<TArticleRead> | null> => {
    try {
      const sections = await findSections();
      const sectionIds = sections?.map((section: Section) => section.id) || [];
      const articlesBySections = await findArticlesByIds({
        ids: sectionIds,
      });
      const articleBySectionsIds = articlesBySections?.map(article => article.id) || [];
      const popularArticles = await findArticlesPopular({
        ids: articleBySectionsIds,
        orderBy: {
          count: 'desc',
        },
        limit: 5,
      });
      if (popularArticles) {
        const result: Array<TArticleRead> = [];
        popularArticles.forEach(popularArticle => {
          const article = articlesBySections?.find(
            article => article.id === popularArticle.articleId
          );
          if (article) {
            const section = sections?.find(section => section.id === article.sectionId);
            if (section) {
              article.sectionSlug = section.slug || '';
              article.projectSlug = section.project.slug || '';
              result.push(article);
            }
          }
        });
        runInAction(() => {
          this.popular = result;
        });
        return result;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadCommonByProjectId = async (
    projectId: number
  ): Promise<Array<TArticleRead> | null> => {
    try {
      const articlesCommon = await findArticlesCommonByProjectId({
        id: projectId,
      });
      const articleIds = articlesCommon?.map(articleCommon => articleCommon.articleId) || [];
      const articles = await findCommonArticlesByIds({
        ids: articleIds,
      });
      if (articles) {
        const result: Array<TArticleRead> = [];
        articles.forEach(article => {
          const data: TArticleRead = this.constructCommonArticle(article);
          result.push(data);
        });
        runInAction(() => {
          this.common = result;
        });
        return result;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadLegalByProjectId = async (
    projectId: number
  ): Promise<Array<TArticleRead> | null> => {
    try {
      const articlesCommon = await findArticlesCommonByProjectId({
        id: projectId,
      });
      const articleIds = articlesCommon?.map(articleCommon => articleCommon.articleId) || [];
      const articles = await findLegalArticlesByIds({
        ids: articleIds,
      });
      if (articles) {
        const result: Array<TArticleRead> = [];
        articles.forEach(article => {
          const data: TArticleRead = this.constructCommonArticle(article);
          result.push(data);
        });
        runInAction(() => {
          this.legal = result;
        });
        return result;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  loadPopularByProjectId = async (
    projectId: number
  ): Promise<Array<TArticleRead> | null> => {
    try {
      const sectionsByProject = await findSectionsByProjectId({
        id: projectId,
      });
      const sectionIds = sectionsByProject?.map((section: Section) => section.id) || [];
      const articlesCommon = await findArticlesCommonByProjectId({
        id: projectId,
      });
      const articleIds = articlesCommon?.map(articleCommon => articleCommon.articleId) || [];
      const articles = await findPopularArticlesByIds({
        ids: articleIds,
      });
      if (articles) {
        const result: Array<TArticleRead> = [];
        articles.forEach(article => {
          const section = sectionsByProject?.find(section => section.id === article.sectionId);
          const data: TArticleRead = this.constructCommonArticle(article, section);
          result.push(data);
        });
        runInAction(() => {
          this.popularInProject = result;
        });
        return result;
      } else {
        return null;
      }
    } catch(error) {
      logError(error);
    }
    return null;
  };

  constructCommonArticle = (article: TArticleRead, section?: TSectionRead) => {
    const result: TArticleRead = {
      ...article,
      projectId: section?.projectId,
    };
    if (section?.slug) {
      result.sectionSlug = section?.slug;
    }
    if (section?.project.slug) {
      result.projectSlug = section?.project.slug;
    }
    const meta = article.meta ? JSON.parse(article.meta) : {};
    if (meta.prevId) {
      result.prevId = meta.prevId;
    }
    if (meta.nextId) {
      result.nextId = meta.nextId;
    }
    return result;
  };

  increasePopularity = async (article: Article) => {
    const articlePopular = await findArticlePopularByArticleId({
      id: article.id,
    });
    if (articlePopular) {
      updateArticlePopular({
        id: articlePopular.id,
        data: {
          count: articlePopular.count + 1,
        },
      });
    } else {
      createArticlePopular({
        data: {
          articleId: article.id,
          count: 1,
        },
      });
    }
  };

  setActiveCommonArticle = (articleId?: number) => {
    if (typeof articleId === 'undefined') {
      delete this.activeCommonArticle;
    } else {
      const activeArticle = this.common?.find(article => article.id === articleId);
      if (activeArticle) {
        this.activeCommonArticle = activeArticle;
      }
    }
  };

  setActiveLegalArticle = (articleId?: number) => {
    if (typeof articleId === 'undefined') {
      delete this.activeLegalArticle;
    } else {
      const activeArticle = this.common?.find(article => article.id === articleId)
      if (activeArticle) {
        this.activeLegalArticle = activeArticle;
      }
    }
  };

}
